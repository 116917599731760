import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const DebugPanel = ({ selectedExam, student, result }) => {
    const debugCheckCookies = async () => {
        try {
            console.log('=== Checking Cookies ===');
            console.log('Document cookies:', document.cookie);
            
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/auth/check-cookies`,
                { withCredentials: true }
            );
            console.log('Backend cookie check response:', response.data);
            
            alert(JSON.stringify({
                documentCookies: document.cookie,
                backendResponse: response.data
            }, null, 2));
        } catch (error) {
            console.error('Cookie check error:', error);
            alert('Error checking cookies: ' + error.message);
        }
    };

    const debugViewCookies = () => {
        try {
            console.log('=== Viewing All Cookies ===');
            const cookies = document.cookie.split(';')
                .reduce((acc, cookie) => {
                    const [name, value] = cookie.trim().split('=');
                    acc[name] = value;
                    return acc;
                }, {});
            console.table(cookies);
            alert(JSON.stringify(cookies, null, 2));
        } catch (error) {
            console.error('View cookies error:', error);
            alert('Error viewing cookies: ' + error.message);
        }
    };

    const debugExamInfo = () => {
        try {
            console.log('=== Debug Info ===');
            const moodleExamInfo = sessionStorage.getItem('moodleExamInfo');
            const isMoodleExam = result?.exam_link && 
                                result.exam_link.includes('soapmoodle.upou.edu.ph');
            
            const debugInfo = {
                result: {
                    id: result?.result_id,
                    exam_link: result?.exam_link,
                    title: result?.title,
                    isMoodleExam: isMoodleExam
                },
                moodleExamInfo: moodleExamInfo ? JSON.parse(moodleExamInfo) : null,
                cookies: {
                    username: Cookies.get('username'),
                    moodleSession: Cookies.get('MoodleSession'),
                    // Add other relevant cookies
                },
                currentUrl: window.location.href,
                timestamp: new Date().toISOString()
            };
            console.log(debugInfo);
            alert(JSON.stringify(debugInfo, null, 2));
        } catch (error) {
            console.error('Debug info error:', error);
            alert('Error showing debug info: ' + error.message);
        }
    };

    const testMoodleRedirect = async () => {
        try {
            console.log('=== Testing Moodle Redirect ===');
            // First check if we have exam info
            const moodleInfo = sessionStorage.getItem('moodleExamInfo');
            if (!moodleInfo) {
                alert('No Moodle exam info found in session');
                return;
            }

            const username = Cookies.get('username');
            const password = Cookies.get('password');
            
            if (!username || !password) {
                alert('Missing Moodle credentials');
                return;
            }

            // Parse the stored exam info first
            const parsedInfo = JSON.parse(moodleInfo);
            console.log('Stored Moodle info:', parsedInfo);

            if (!parsedInfo.examLink) {
                alert('No exam link found in stored exam info');
                return;
            }

            console.log('Attempting Moodle login...');
            const loginResponse = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/auth/moodle-login`,
                { username, password },
                { 
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );

            console.log('Login response:', loginResponse.data);

            if (loginResponse.data.status === "success") {
                // Check cookies after login
                const cookieCheck = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/auth/check-cookies`,
                    { withCredentials: true }
                );
                console.log('Cookie check response:', cookieCheck.data);

                // Attempt redirect using the correct endpoint path
                const redirectUrl = `${process.env.REACT_APP_BACKEND_URL}/auth/moodle-redirect`;  // Updated path
                console.log('Sending redirect request to:', redirectUrl);
                console.log('With exam link:', parsedInfo.examLink);
                
                const redirectResponse = await axios.post(redirectUrl, 
                    { targetUrl: parsedInfo.examLink },
                    { 
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }
                    }
                );

                console.log('Redirect response:', redirectResponse.data);

                if (redirectResponse.data.redirectUrl) {
                    window.location.href = redirectResponse.data.redirectUrl;
                } else {
                    alert('No redirect URL received from server');
                }
            } else {
                alert('Failed to establish Moodle session');
            }
        } catch (error) {
            console.error('Test redirect error:', error);
            const errorDetails = error.response ? {
                status: error.response.status,
                statusText: error.response.statusText,
                data: error.response.data,
                url: error.response.config.url
            } : error;
            console.error('Error details:', errorDetails);
            
            const errorMessage = error.response ? 
                `${error.response.status} - ${error.response.data?.message || error.response.statusText}` :
                error.message;
            alert(`Error testing redirect: ${errorMessage}`);
        }
    };

    return (
        <div className="fixed bottom-4 right-4 bg-gray-800 text-white p-4 rounded-lg shadow-lg z-50">
            <h3 className="font-bold mb-2">Debug Panel</h3>
            <div className="space-y-2">
                <div className="text-xs mb-2">
                    <p>Environment: {process.env.NODE_ENV}</p>
                    <p>Backend URL: {process.env.REACT_APP_BACKEND_URL}</p>
                    <p>Current Page: {window.location.pathname}</p>
                    <p>Has Result: {result ? 'Yes' : 'No'}</p>
                </div>
                <button
                    type="button"
                    onClick={debugCheckCookies}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                >
                    Check Cookies
                </button>
                <button
                    type="button"
                    onClick={debugViewCookies}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full"
                >
                    View All Cookies
                </button>
                <button
                    type="button"
                    onClick={debugExamInfo}
                    className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded w-full"
                >
                    Debug Info
                </button>
                <button
                    type="button"
                    onClick={testMoodleRedirect}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full"
                >
                    Test Moodle Redirect
                </button>
            </div>
        </div>
    );
};

export default DebugPanel; 