/* global cv */
import React, { useEffect, useRef, useState } from "react";
//import axios from '../utils/axios';
import axios from 'axios';
import Header from "../components/Header";
import Modal from "../components/Modal";
import {useParams} from "react-router-dom";
import RecordRTC from 'recordrtc';
import Loading from "./Loading";
import DebugPanel from '../components/DebugPanel';
import Cookies from 'js-cookie';

export default function ExamProper() {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const { result_id } = useParams();
    const [result, setResult] = useState({});
    const [recorder, setRecorder] = useState(null);
    const [videoDevices, setVideoDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState("");
    const [examStarted, setExamStarted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [screenshotIntervalId, setScreenshotIntervalId] = useState(null);
    const [isContentAvailable, setIsContentAvailable] = useState(false);
    const elapsedTimeRef = useRef(0);  // Ref to store the latest elapsed time

    const handleOpenModal = () => setIsModalOpen(true);
    const screenshotSecondsInterval = 3;

    // RSV Added this for Moodle SSO    

    const handleDoneExam = () => {
        setLoading(true);
        clearInterval(screenshotIntervalId);
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/exam-done/${result_id}`)
            .then(response => {
                console.log('Exam done', response.data);
            })
            .catch(error => {
                console.error('Error processing exam done', error);
            });

        if (recorder) {
            recorder.stopRecording(async () => {
                const blob = recorder.getBlob();
                const formData = new FormData();
                formData.append('file', blob, `video_${Date.now()}.webm`);
                formData.append('result_id', result_id);

                try {
                    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/results/video`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    console.log('Video uploaded successfully');
                    window.location.href = '/student-dashboard';
                } catch (error) {
                    console.error('Error uploading video', error);
                }
            });
        }
    };

    const startWebcamFeed = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,
                    width: { ideal: 640 },
                    height: { ideal: 480 }
                }
            });
            
            const video = videoRef.current;
            if (video) {
                video.srcObject = stream;
                
                // Wait for video to be ready
                return new Promise((resolve) => {
                    video.onloadedmetadata = () => {
                        video.play();
                        // Wait a bit to ensure dimensions are set
                        setTimeout(() => {
                            if (video.videoWidth > 0 && video.videoHeight > 0) {
                                console.log('Video dimensions ready:', video.videoWidth, 'x', video.videoHeight);
                                resolve();
                            }
                        }, 500);
                    };
                });
            }
        } catch (err) {
            console.error("Error accessing the camera:", err);
            if (err.name === "NotAllowedError") {
                alert("Camera access is not allowed. Please enable camera permissions in your browser settings.");
            } else {
                alert("Could not access the camera. Please ensure you have a camera available and have granted permission to use it.");
            }
        }
    };

    useEffect(() => {
        console.log('=== ExamProper Initial Load ===');
        
        // Get result details
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/get_result_details/${result_id}`)
            .then(response => {
                console.log('Result details:', response.data);
                setResult(response.data);
                
                // Check if this is a Moodle exam by checking quiz_url pattern
                const isMoodleExam = response.data.exam_link && 
                                    response.data.exam_link.includes('soapmoodle.upou.edu.ph');
                
                if (isMoodleExam) {
                    console.log('Moodle exam detected:', response.data.exam_link);
                    // Store exam info in session storage
                    const examInfo = {
                        examId: result_id,
                        timestamp: Date.now(),
                        examLink: response.data.exam_link,
                        isMoodleExam: true,
                        returnUrl: `${window.location.origin}/student-dashboard`
                    };
                    sessionStorage.setItem('moodleExamInfo', JSON.stringify(examInfo));
                    console.log('Stored Moodle exam info:', examInfo);
                }
            })
            .catch(error => {
                console.error('Error fetching result:', error);
            });

        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const videoInputDevices = devices.filter(device => device.kind === 'videoinput');
                setVideoDevices(videoInputDevices);
                if (videoInputDevices.length > 0) {
                    setSelectedDeviceId(videoInputDevices[0].deviceId);
                }
            })
            .catch(error => {
                console.error('Error fetching video devices', error);
            });
        startWebcamFeed();
    }, [result_id]);

    const startRecordingAndScreenshots = async () => {
        try {
            const video = videoRef.current;
            if (!video || !video.srcObject) {
                console.error('Video not initialized');
                await startWebcamFeed(); // Try to reinitialize
            }

            // Ensure video is ready
            if (video && video.srcObject) {
                const stream = video.srcObject;
                const options = {
                    type: 'video',
                    mimeType: 'video/webm',
                    disableLogs: true
                };
                const recorderInstance = new RecordRTC(stream, options);
                recorderInstance.startRecording();
                setRecorder(recorderInstance);

                // Start taking screenshots
                const screenshotInterval = setInterval(() => {
                    takeScreenshot();
                }, screenshotSecondsInterval * 1000);
                setScreenshotIntervalId(screenshotInterval);
            }
        } catch (error) {
            console.error('Error starting recording:', error);
            alert('Error starting video recording. Please refresh the page and try again.');
        }
    };
    
    const startExam = () => {
        setExamStarted(true);
        
        // Start the timer and recording first, before handling exam link
        const startTime = Date.now();
        const timer = setInterval(() => {
            const elapsed = Math.floor((Date.now() - startTime) / 1000);
            setElapsedTime(elapsed);
            elapsedTimeRef.current = elapsed;
        }, 1000);

        // Start recording and screenshots
        startRecordingAndScreenshots();

        // Handle exam link redirect after a short delay
        // setTimeout(() => {
            if (result.exam_link) {
                if (result.exam_link.includes('soapmoodle.upou.edu.ph')) {
                    console.log('Starting Moodle exam flow');
                    handleMoodleAccess(result.exam_link);
                } else {
                    console.log('Starting regular exam flow');
                    let examLink = result.exam_link;
                    if (!examLink.startsWith('http://') && !examLink.startsWith('https://')) {
                        examLink = `http://${examLink}`;
                    }
                    window.open(examLink, '_blank');
                }
            }
        //}, 2000); // 2 second delay to ensure recording starts properly

        return () => clearInterval(timer);
    };
    
    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
        const seconds = totalSeconds - hours * 3600 - minutes * 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };
    

    const takeScreenshot = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        
        // Add null checks
        if (!video || !canvas) {
            console.error('Video or canvas element not found');
            return;
        }

        // Check if video is actually playing and has valid dimensions
        if (!video.readyState === video.HAVE_ENOUGH_DATA) {
            console.error('Video not ready');
            return;
        }

        try {
            // Wait for video dimensions to be available
            if (video.videoWidth === 0 || video.videoHeight === 0) {
                console.log('Waiting for video dimensions...');
                setTimeout(takeScreenshot, 500); // Try again in 500ms
                return;
            }

            const context = canvas.getContext('2d');
            if (!context) {
                console.error('Could not get canvas context');
                return;
            }

            // Set canvas dimensions to match the video dimensions
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Draw the video frame onto the canvas
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Convert the canvas content to a blob and upload it
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error('Failed to create blob from canvas');
                    return;
                }

                const formData = new FormData();
                const timestamp = formatTime(elapsedTimeRef.current);
                formData.append('file', blob, `screenshot_${Date.now()}.png`);
                formData.append('result_id', result_id);
                formData.append('timestamp', timestamp);

                axios.post(`${process.env.REACT_APP_BACKEND_URL}/results/screenshot`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    console.log('Screenshot uploaded successfully', response.data);
                }).catch(error => {
                    console.error('Error uploading screenshot', error);
                });
            }, 'image/png');
        } catch (error) {
            console.error('Error taking screenshot:', error);
        }
    };    

    const handleMoodleAccess = async (examLink) => {
        try {
            const username = Cookies.get('username');
            const password = Cookies.get('password');
            const accessToken = Cookies.get('accessToken');
            const moodleToken = Cookies.get('moodleToken');

            console.log('Username:', username);
            console.log('Password:', password);
            console.log('Access Token:', accessToken);
            console.log('Moodle Token:', moodleToken);
            console.log('Exam Link:', examLink);
            console.log('React App Backend URL:', process.env.REACT_APP_BACKEND_URL);

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/auth/moodle-login-session`,
                {
                    username: username,
                    password: password,
                    targetUrl: examLink
                },
                {
                    withCredentials: true,  // Important for cookie handling
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            if (response.data.status === 'success') {
                // Open in new window
                const newWindow = window.open(response.data.redirectUrl, '_blank');
                if (!newWindow) {
                    alert('Please allow popups for this site');
                }
            } else {
                alert('Failed to connect to Moodle. Please try again.');
            }

        } catch (error) {
            console.error('Moodle access error:', error.response?.data || error.message);
            alert('Failed to access Moodle. Please try again.');
        }
    };

    /*const redirectToMoodleQuiz = (quizId, token) => {
        window.location.replace(`https://soapmoodle.upou.edu.ph/mod/quiz/view.php?id=${quizId}&wstoken=${token}`);
      };*/

    const handleMoodleAccessV2 = async (examLink) => {
        try {
          console.log('=== Handling Moodle Access (Token-Based) ===');
          console.log('Exam Link:', examLink);
      
          const username = Cookies.get('username');
          const password = Cookies.get('password');
      
          if (!username || !password) {
            console.error('Missing Moodle credentials');
            return;
          }
      
          // Get Moodle token from your backend
          const tokenResponse = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/auth/moodle-token`, // New endpoint
            { username, password },
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
          );
      
          const token = tokenResponse.data.token;
          console.log('Moodle token:', token);
      
          if (token) {
            // Append token to the Moodle exam link
            //const moodleExamUrl = `${examLink}&wstoken=${token}`;
      
            // Redirect to Moodle exam
            //window.open(moodleExamUrl, '_blank');

            const redirectToMoodleQuiz = async (examLink, token) => {
                try {
                  const response = await axios.post(
                      `${process.env.REACT_APP_BACKEND_URL}/auth/moodle-redirect`,
                      { targetUrl: examLink, token: token },
                      {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }
                      }
                  );
                  console.log('Redirect URL:', response.data.redirectUrl);
                  window.open(response.data.redirectUrl, '_blank');
                } catch(error){
                    console.error(error);
                }
              };

            redirectToMoodleQuiz(examLink, token);
      
            // Optional: Verify session on backend
            /*try {
                await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/auth/verify-moodle-session`,
                    { targetUrl: moodleExamUrl },
                    { withCredentials: true }
                );
            } catch (verifyError) {
                console.error('Session verification error:', verifyError);
            } */
          } else {
            console.error('Failed to retrieve Moodle token');
          }
        } catch (error) {
          console.error('Moodle access error:', error);
        }
      };
    
    // Add new function to handle Moodle access
    const handleMoodleAccess_old = async (examLink) => {
        try {
            console.log('=== Handling Moodle Access ===');
            console.log('Exam Link:', examLink);

            const username = Cookies.get('username');
            const password = Cookies.get('password');
            const moodleToken = Cookies.get('moodleToken');

            if (!username || !password) {
                console.error('Missing Moodle credentials');
                return;
            }

            // Step 1: Authenticate with Moodle
            console.log('Step 1: Authenticating with Moodle...');
            const loginResponse = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/auth/moodle-login`,
                { username, password },
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );

            console.log('Login response:', loginResponse.data);            

            if (loginResponse.data.status === "success") {                
                
                // Step 2: Create and submit form for redirect
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = examLink; // Direct to Moodle URL
                form.target = '_blank';

                // Add Moodle-specific form fields
                const fields = {
                    'username': username,
                    'password': password,
                    'logintoken': loginResponse.data.logintoken || '', // If provided by backend
                    'anchor': '',
                    'rememberusername': 1
                };

                Object.entries(fields).forEach(([name, value]) => {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = name;
                    input.value = value;
                    form.appendChild(input);
                });

                // Add auto-submit script
                const script = document.createElement('script');
                script.textContent = 'document.forms[0].submit();';
                form.appendChild(script);

                // Submit form
                document.body.appendChild(form);
                form.submit();
                document.body.removeChild(form);

                // Optional: Make a call to your backend to verify the session
                /*try {
                    await axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}/auth/verify-moodle-session`,
                        { targetUrl: examLink },
                        { 
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            }
                        }
                    );
                } catch (verifyError) {
                    console.error('Session verification error:', verifyError);
                } */
               // ... after receiving the token from your backend ...

                /*const redirectToMoodleQuiz = (examLink, moodleToken) => {
                    window.location.replace(`${examLink}&wstoken=${moodleToken}`);
                };                
                // Example usage:
                // redirectToMoodleQuiz(123, receivedToken); 
                */
                //window.location.replace(`${examLink}&wstoken=${moodleToken}`);

            } else {
                console.error('Moodle authentication failed');
            }
        } catch (error) {
            console.error('Moodle access error:', error);
            console.error('Error details:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
            });
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="flex flex-col w-screen min-h-screen bg-body">
            <Header isLogoutBlocked={true} />
            <div className="body flex flex-col w-screen h-full py-16 items-center space-y-10 ">
                <h1 className="text-2xl font-bold text-maroon">{result ? result.title: ''}</h1>
                {loading && (
                    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                        <div className="bg-white p-4 rounded-lg text-center">
                            <Loading />
                            <p className="mt-2 text-maroon">Authenticating with Moodle...</p>
                        </div>
                    </div>
                )}
                {examStarted && 
                    (<div className="flex space-x-6">
                        <h1 className="bg-maroon rounded-lg p-2 text-white font-semibold">
                            Elapsed Time: {formatTime(elapsedTime)}
                        </h1>
                    </div>
                )}
                <div>
                    <video 
                        ref={videoRef} 
                        style={{ display: 'inline' }} 
                        width="640" 
                        height="480"
                        playsInline
                        autoPlay
                        muted
                    ></video>
                    <canvas 
                        ref={canvasRef} 
                        width="640" 
                        height="480" 
                        style={{ display: 'none' }}
                    ></canvas>
                </div>
                {!examStarted && (
                    <div className="flex flex-col items-center space-y-4">                        
                        <button 
                            className="bg-maroon text-white font-bold p-2 rounded-lg" 
                            onClick={startExam}
                        >
                            Start Exam
                        </button>
                    </div>
                )}
                {examStarted && (
                    <div className="flex flex-col items-center space-y-4">
                        <button className="bg-maroon text-white font-bold p-2 rounded-lg" onClick={handleOpenModal}> Done </button>
                    </div>
                )}
            </div>
            {isModalOpen && (
                <Modal
                    onAction={handleDoneExam}
                    actionButton='Yes'
                    actionColor='green-button'
                    actionColorText='body'
                    onClose={() => setIsModalOpen(false)}
                >
                    <h1 className='text-lg my-4 font-semibold'>
                        Is your exam done?
                    </h1>
                    <hr />
                    <h1 className='text-lg my-4'>
                        By clicking "Yes", you are confirming that you are done with your exam and understand that this action cannot be undone.
                    </h1>
                </Modal>
            )}
            
            {/* Add Debug Panel */}
            <DebugPanel 
                selectedExam={null}
                student={null}
                result={result}
            />
        </div>
    );
}
