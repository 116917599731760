import React, { useState, useEffect } from 'react';
import InputField from '../components/InputField';
import { useLocation } from 'react-router-dom';
import Modal from '../components/Modal';
import axios from 'axios';
import DragDropFiles from '../components/DragDropFiles';

// RSV added for Moodle integration
import Cookies from 'js-cookie';

// No validation for the fields yet
function SignUp() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const [isGoogleRedirect, setIsGoogleRedirect] = useState(false);
  const [identifier, setIdentifier] = useState(''); // Student number or employee number
  const [userRole, setUserRole] = useState('examinee');
  const [photo, setPhoto] = useState(null);

  // RSV added to disable fields if username exists in params
  const [fieldsDisabled, setFieldsDisabled] = useState(false);  

  // RSV added for Moodle integration
  const [isMoodleRedirect, setIsMoodleRedirect] = useState(false); 
  const [moodleId, setMoodleId] = useState(null);
  
  // RSV Added to convert an image from the source site to a file
  const urlToFile = async (url, filename) => {
    try {
      // Use Python backend endpoint to proxy the image
      const proxyUrl = `${process.env.REACT_APP_BACKEND_URL}/proxy_image?url=${encodeURIComponent(url)}`;
      const response = await fetch(proxyUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch image through proxy');
      }
      const blob = await response.blob();
      return new File([blob], filename, { type: blob.type || 'image/jpeg' });
    } catch (error) {
      console.error('Error fetching image:', error);
      return null;
    }
  };  

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setEmail(searchParams.get('email') || '');
    setFirstName(searchParams.get('firstName') || '');
    setLastName(searchParams.get('lastName') || '');

    // RSV added to check if username exists in params and disable fields accordingly
    // const username = searchParams.get('username');  
    const username = Cookies.get('username');      
                
    if (username) {
      setUsername(username);
      //setPassword(Cookies.get('password'));
      //setConfirmPassword(Cookies.get('password'));
      if (Cookies.get('isMoodle')){
        setIsMoodleRedirect(true);
        setMoodleId(Cookies.get('moodleUserId'));
        setIdentifier(Cookies.get('moodleIdNumber'));
      }
      setFieldsDisabled(true);
      setIsGoogleRedirect(false);
    } else {
      setIsGoogleRedirect(!!searchParams.get('email'));
    }    
    
    // RSV Added to convert an image from the source site to a file
    //const imageUrl = searchParams.get('imagePreviewUrl');
    const imageUrl = Cookies.get('imagePreviewUrl');
    if (imageUrl) {
      setImagePreviewUrl(imageUrl);
      // Convert URL to File object and set as photo
      urlToFile(imageUrl, 'profile-photo.jpg')
        .then(file => {
          if (file) {
            setPhoto(file);
          }
        });
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // RSV modified validation to skip password check when fields are disabled
    // Modified validation to Moodle fields
    //if (!firstName || !lastName || (!isGoogleRedirect && !fieldsDisabled && !username) 
    // || !identifier || !email || (!isGoogleRedirect && !fieldsDisabled && (!password || !confirmPassword))) {
    if (!firstName || !lastName || (!isGoogleRedirect && !fieldsDisabled && !username) || !email) {
      setError('All fields are required.');
      return;
    }
    if (userRole == 'examinee' && !photo){
      setError('Please upload a photo.');
      return;
    }
    setError('');
    
    // RSV Only check password match if passwords are required
    if (!fieldsDisabled && !isGoogleRedirect && password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/sign-up`, {
          user_role: userRole,
          user_status: 'pending',
          first_name: firstName, 
          last_name: lastName, 
          username: fieldsDisabled ? username : (isGoogleRedirect ? '' : username),   //RSV modified to use fieldsDisabled
          student_number: userRole === 'examinee' ? identifier : '',
          employee_number: userRole === 'teacher' ? identifier : '', 
          email, 
          password: fieldsDisabled ? '' : (isGoogleRedirect ? '' : password),   // RSV modified to use fieldsDisabled
          is_google: isGoogleRedirect ? true : false,
          is_moodle: isMoodleRedirect ? true : false,
          moodle_user_id: isMoodleRedirect ? (isNaN(moodleId) ? null : parseInt(moodleId, 10)) : null
          //profile_picture_url: imagePreviewUrl,  // RSV added to capture URL          
      });

      if (photo) {
        const formData = new FormData();
        formData.append('file', photo);

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/upload_photo/${response.data.user_id}`, formData, {
            headers: {              
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            console.log(response.data);
        })
        .catch(error => {
            console.error(error);
            alert('Failed to upload file');
        });
    }

      
      if (response.ok){
        // RSV added to clear cookies
        Cookies.remove('accessToken');
        Cookies.remove('userRole');
        Cookies.remove('userId');
        Cookies.remove('userStatus');
        Cookies.remove('email');
        Cookies.remove('firstName');
        Cookies.remove('lastName');
        Cookies.remove('imagePreviewUrl');
        Cookies.remove('isMoodle');
        Cookies.remove('moodleUserId');
        setShowModal(true);
      } else {
        setError(response.errorData.error || 'An error occurred. Please try again.');
        setShowModal(false);
      }   
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message || 'An error occurred.';
      // Handle specific error messages
      // RSV removed validation on ID numbers since they are not required in Moodle
      /*if (errorMessage === 'Student number already exists') {
        setError('Account already exists with this student number. Please log in.');
        setShowModal(false);
      } else if (errorMessage === 'Employee number already exists') {
        setError('Account already exists with this employee number. Please log in.');
        setShowModal(false);
      } else */
      if (errorMessage === 'Email already exists') {
        setError('Account already exists with this email. Please log in.');
        setShowModal(false);
      } else if (errorMessage === 'Username already exists') {
        setError('Username already exists. Please choose another.');
        setShowModal(false);
      } else {
        setShowModal(true);
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.href = '/login';
  };

  const handleRoleChange = (e) => {
    setUserRole(e.target.value);
    setIdentifier('');
  }

  const [files, setFiles] = useState(null);

    function handleDrop(event) {
        event.preventDefault();
        // if the uploaded is not a photo, do not set the photo
        if (!event.dataTransfer.files[0].type.includes('image')) {
            console.error('Invalid file type');
        }
        else{
            setPhoto(event.dataTransfer.files[0]);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(event.dataTransfer.files[0]);

        }
    };

    const handlePhotoUpload = (uploadedPhoto) => {
      if (!photo) {
          setPhoto(uploadedPhoto);
          // Convert the uploaded file to a URL for preview
          const reader = new FileReader();
          reader.onloadend = () => {
              setImagePreviewUrl(reader.result);
          };
          reader.readAsDataURL(uploadedPhoto);
      }
  };

    const handleUpload = (event) => {
        if (!photo) {
            const uploadedFile = event.target.files[0];
            handlePhotoUpload(uploadedFile);
        }
    };

  const dragDropFilesProps = {
    handleDrop: handleDrop,
    handleUpload: handleUpload,
    files: photo,
    setFiles: setPhoto
  };

  // RSV Added to remove the pre-uploaded photo from Moodle
  const handleRemovePhoto = () => {
    setPhoto(null);
    setImagePreviewUrl('');
  };

  return (
    <div className="flex flex-col min-h-screen w-screen align-middle justify-center items-center bg-body">
      <h1 className="text-3xl font-bold text-center text-maroon mb-6">Specialized Online Assessment Platform - Prototype</h1>

      {error && <div className="text-red-500 text-center mb-4">{error}</div>}

      <form className="flex flex-col mt-8 space-y-4 w-1/3" style={{ maxWidth: "590px", minWidth: "800px" }} onSubmit={handleSubmit}>
        <div className="flex flex-row space-x-10">
          <div className="flex flex-col space-y-2 w-full">
            <div className="flex flex-row space-x-4">
              <InputField 
                type="text" 
                label="First Name" 
                value={firstName} 
                onChange={e => setFirstName(e.target.value)} 
                placeholder="First Name"
                disabled={fieldsDisabled || (isGoogleRedirect && !!firstName)}/>
              <InputField 
                type="text" 
                label="Last Name" 
                value={lastName} 
                onChange={e => setLastName(e.target.value)} 
                placeholder="Last Name"
                disabled={fieldsDisabled || (isGoogleRedirect && !!lastName)}/>
            </div>
              {!isGoogleRedirect && (
                <InputField 
                type="text" 
                label="Username" 
                value={username} 
                onChange={e => setUsername(e.target.value)} 
                placeholder="Username"
                disabled={fieldsDisabled}/>  
              )}
              <InputField 
                type="email" 
                label="Email" 
                value={email} 
                onChange={e => 
                setEmail(e.target.value)} 
                placeholder="e.g. juandelacruz@up.edu.ph"
                disabled={fieldsDisabled || (isGoogleRedirect && !!email)} />              
              <label htmlFor="userRole" className="block text-sm font-medium text-dark-gray">
                Role:
              </label>
              <select
                id="userRole"
                value={userRole}
                onChange={handleRoleChange}
                className="form-control border-6 border-light-gray py-2 px-3 text-dark-gray rounded-md bg-white"
                disabled={fieldsDisabled || (isGoogleRedirect && !!email)} 
              >
                <option value="examinee">Student</option>
                <option value="teacher">Teacher</option>                
              </select>

              <InputField
                type="text"
                label={userRole === 'examinee' ? 'Student Number' : 'Employee Number'}
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                placeholder={userRole === 'examinee' ? 'e.g. 2020-12345' : 'e.g. 2020-12345'}
                disabled={fieldsDisabled || (isGoogleRedirect && !!email)}    
              /> 
            {!isGoogleRedirect && !fieldsDisabled && (
              <div className="flex flex-row space-x-4">
                <InputField 
                  type="password" 
                  label="Password" 
                  value={password} 
                  onChange={e => setPassword(e.target.value)} 
                  placeholder="********"/>
                <InputField 
                  type="password" 
                  label="Confirm Password" 
                  value={confirmPassword} 
                  onChange={e => setConfirmPassword(e.target.value)} 
                  placeholder="********"/>
              </div>
            )}
          </div>
          {userRole !== 'teacher' && (
            <div>
              <h1 className='text-sm font-medium '>Upload your photo</h1>
              {imagePreviewUrl ? (
                <div className='border border-maroon relative'>
                  <img 
                    src={imagePreviewUrl} 
                    alt="Preview" 
                    style={{ width: 'auto', height: '100%', maxHeight:'80vh', objectFit: 'cover' }} 
                  />
                  <button
                    type="button"
                    onClick={handleRemovePhoto}
                    className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                  >
                    ✕
                  </button>
                </div>
              ) : (
                <DragDropFiles {...dragDropFilesProps} />
              )}
            </div>
          )}
        </div>        
        <div className='flex w-full justify-center align-middle'>
          <button type="submit" className="group relative justify-center py-3 px-6 border border-transparent text-sm font-medium rounded-md text-white bg-maroon hover:bg-red-950 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Submit Photo
          </button>
        </div>
        <div className="flex flex-col text-sm items-center justify-center align-middle space-y-2">
          <a href="/login" className="font-medium text-maroon hover:text-indigo-500">
            Cancel
          </a>
        </div>
      </form>
      {showModal && (
        <Modal
          onClose={handleCloseModal}
          closeText="Close"
        >
          <div className="flex flex-col items-center justify-center p-4">
            <p>Your account is being verified by the admin.</p>
          </div>
        </Modal>
      )}  
    </div>
  );

}

export default SignUp;