import React, {useState} from "react";
import FormField from "./FormField";
import moment from "moment";
import axios from "axios";

import ReactDOM from 'react-dom';

const CreateExamModal = ({ selectedExam, onClose, selectedTeacher, setSnackbarMessage}) => {

    const initialStartTime = (selectedExam ? moment(selectedExam.start_time) : null);
    const initialEndTime = selectedExam ? moment(selectedExam.end_time) : null;

    console.log("initialStartTime", initialStartTime);

    const [timeStart, setTimeStart] = useState(selectedExam ? initialStartTime.format('HH:mm'): null);
    const [timeEnd, setTimeEnd] = useState(selectedExam ? initialEndTime.format('HH:mm'): null);
    const [dateStart, setDateStart] = useState(selectedExam ? initialStartTime.format('YYYY-MM-DD'): null);
    const [dateEnd, setDateEnd] = useState(selectedExam ? initialEndTime.format('YYYY-MM-DD'): null);
    const [title, setTitle] = useState(selectedExam ? selectedExam.title : '');
    const [examURL, setExamURL] = useState(selectedExam ? selectedExam.exam_link : '');

    const timeStartField = {
        label: "Start Time",
        guide: null,
        icon: null,
        type: "time"
    }

    const timeEndField = {
        label: "End Time",
        guide: null,
        icon: null,
        type: "time"
    }

    const dateStartField = {
        label: "Start Date",
        guide: null,
        icon: null,
        type: "date",
    }

    const dateEndField = {
        label: "End Date",
        guide: null,
        icon: null,
        type: "date"
    }

    // Validation message states
    const [titleValidationMsg, setTitleValidationMsg] = useState('');
    const [examURLValidationMsg, setExamURLValidationMsg] = useState('');
    const [timeStartValidationMsg, setTimeStartValidationMsg] = useState('');
    const [timeEndValidationMsg, setTimeEndValidationMsg] = useState('');
    const [dateStartValidationMsg, setDateStartValidationMsg] = useState('');
    const [dateEndValidationMsg, setDateEndValidationMsg] = useState('');
    // Add more validation message states as needed

    const validateForm = () => {
        let isValid = true;

        // Title validation
        if (!title) {
            setTitleValidationMsg("Title is required.");
            isValid = false;
        } else {
            setTitleValidationMsg("");
        }

        // Exam URL validation
        if (!examURL) {
            setExamURLValidationMsg("Exam URL is required.");
            isValid = false;
        } else {
            setExamURLValidationMsg("");
        }

        // if (!timeStart) {
        //     setTimeStartValidationMsg("Start Time is required.");
        //     isValid = false;
        // }

        // if (!timeEnd) {
        //     setTimeEndValidationMsg("End Time is required.");
        //     isValid = false;
        // }

        // if (!dateStart) {
        //     setDateStartValidationMsg("Start Date is required.");
        //     isValid = false;
        // }

        // if (!dateEnd) {
        //     setDateEndValidationMsg("End Date is required.");
        //     isValid = false;
        // }


        return isValid;
    };

    

    const handleSubmitNewExam = (newExam) => {
        console.log("CREATING NEW EXAM")
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/exams/create`, newExam)
            .then((res) => {
                console.log(res.data);
                setSnackbarMessage(`${newExam.title} successfully created!`);
                onClose();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleSubmitEditExam = (editedExam) => {
        console.log('selectedExam._id ', selectedExam._id);
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/exams/${selectedExam._id}/edit`, editedExam)
            .then((res) => {
                console.log(res.data);
                setSnackbarMessage(`${editedExam.title} edited successfully!`);
                onClose();
            })
            .catch((error) => {
                console.error(error);
            });
    };



    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent form submission if using a <form> tag
        const isFormValid = validateForm();
        // RSV fixed due to bug
        /*const newExam = {
            title: title,
            exam_code: Math.random().toString(36).substr(2, 8),
            exam_link: examURL,
            // start_time: moment(`${dateStart} ${timeStart}`).toISOString(),
            // end_time: moment(`${dateEnd} ${timeEnd}`).toISOString(),
            teacher_id: selectedTeacher,
            exam_results: []
        };*/
        
        if (isFormValid && !selectedExam) {
            // RSV added and fixed due to bug
            const newExam = {
                title: title,
                exam_code: Math.random().toString(36).substr(2, 8),
                exam_link: examURL,
                // start_time: moment(`${dateStart} ${timeStart}`).toISOString(),
                // end_time: moment(`${dateEnd} ${timeEnd}`).toISOString(),
                teacher_id: selectedTeacher,
                exam_results: []
            };
            handleSubmitNewExam(newExam);
        } else if (isFormValid && selectedExam) {
            // RSV added and fixed due to bug
            const newExam = {
                title: title,
                //exam_code: Math.random().toString(36).substr(2, 8),
                exam_link: examURL,
                // start_time: moment(`${dateStart} ${timeStart}`).toISOString(),
                // end_time: moment(`${dateEnd} ${timeEnd}`).toISOString(),
                teacher_id: selectedTeacher
                //,
                //exam_results: []
            };
            handleSubmitEditExam(newExam);            
        } else {
            console.log(isFormValid, selectedExam)
        }
    };
    


    return ReactDOM.createPortal(
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            {/* input fields: Title, Exam URL, Start Date, End Date */}
            <div className="bg-white p-4 rounded-lg shadow-lg  max-w-3xl w-2/3 space-y-6">
                <h1 className="text-2xl font-bold text-maroon">
                    {selectedExam ? 'Edit Exam' : 'Create Exam'}
                </h1>
                
                <div className="flex flex-col space-y-4">
                    <h1 className="font-bold text-base">Title</h1>
                    <input
                        className="rounded-md bg-white border border-light-gray px-5"
                        style={{height:"52px"}}
                        placeholder='e.g. CMSC 170 Long Exam'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    {titleValidationMsg && <p className="text-xs text-red-500">{titleValidationMsg}</p>}
                    <h1 className="font-bold text-base">Exam URL</h1>
                    <input
                        className="rounded-md bg-white border border-light-gray px-5"
                        style={{height:"52px"}}
                        placeholder='e.g. google.com'
                        value={examURL}
                        onChange={(e) => setExamURL(e.target.value)}
                    />
                    
                </div>
                <div className="text-right mt-4">
                    <button onClick={onClose} className="py-2 px-4 bg-dark-gray text-white hover:brightness-90 rounded font-semibold">
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="py-2 px-4 bg-maroon text-white hover:brightness-90 rounded ml-2 font-semibold"
                        >
                        {selectedExam ? 'Save Changes' : 'Create Exam'}
                    </button>
                </div>
            </div>
        </div>,
        document.body
    );
}

export default CreateExamModal;