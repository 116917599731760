import React from "react";
import { useState, useEffect } from "react";
import ExamStatus from "./ExamStatus";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Pagination from "./Pagination";
import StatusFilter from "./StatusFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import Snackbar from "./Snackbar";

const ExamTable = ({exams, student, handlePending}) => {

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  
  const [page, setPage] = useState(1);
  const entriesPerPage = 10;
  const [totalPages, setTotalPages] = useState(Math.ceil(exams.length / entriesPerPage));

  // RSV added for Moodle access
  const [snackBarMessage, setSnackBarMessage] = useState('');

  // Add this near the top of your component, after your state declarations
  const isDevelopment = process.env.NODE_ENV === 'development';
  console.log('Current environment:', process.env.NODE_ENV); // Debug log

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleStartExam = async () => {
    // Check if devices are available before changing exam status
    const devicesAvailable = await checkDevices(); // Implement this function to check devices
    if (!devicesAvailable) {
        console.error('No video devices available. Cannot start exam.');
        return; // Exit if no devices are available
    }

    // Proceed to update the exam status
    axios.put(`${process.env.REACT_APP_BACKEND_URL}/results`, {
        result_id: selectedExam.result_id,
        exam_status: 'In Progress' // Assuming 'completed' is the status you want to set
    })
    .then(response => {
        console.log('Exam status updated successfully', response.data);
        handleCloseModal();
        navigate(`/exam-proper/${selectedExam.result_id}`);
    })
    .catch(error => {
        console.error('Error updating exam status', error);
    });
    console.log("Exam started");
  };

  // Function to check for available video devices
  const checkDevices = async () => {
    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoInputDevices = devices.filter(device => device.kind === 'videoinput');
        return videoInputDevices.length > 0; // Return true if there are video devices
    } catch (error) {
        console.error('Error checking devices:', error);
        return false; // Return false if there was an error
    }
  };

  const handleStatusChange = (statuses) => {
    setSelectedStatuses(statuses);
  }

  const handleSort = (column) => {
    if (sortColumn === column) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
        setSortColumn(column);
        setSortOrder("asc");
    }
  };  

  const getStatusOrder = (status) => {
    const order = {
        "Not Taken": 1,
        "In Progress": 2,
        "To Evaluate": 3,
        "Flagged": 4,
        "Safe": 5,
    };
    return order[status] || 5;
  };

  const sortedExams = [...exams].sort((a, b) => {
    if (sortColumn){
      let aValue = a[sortColumn];
      let bValue = b[sortColumn];
      console.log(a)

      if (sortColumn === "exam_status") {
        aValue = getStatusOrder(aValue);
        bValue = getStatusOrder(bValue);
      } else {
        console.log(aValue, bValue)
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    }
    return 0;
  });
  
  const filteredExams = sortedExams.filter((exam) => {
    const matchesSearchTerm = 
      exam.title.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesStatus = selectedStatuses.length === 0 || selectedStatuses.includes(exam.exam_status);
    return matchesSearchTerm && matchesStatus;
  });
  
  useEffect(() => {
    setTotalPages(Math.max(1, Math.ceil(filteredExams.length / entriesPerPage)));
  }, [filteredExams.length]);


  const paginatedExams = filteredExams.slice((page - 1) * entriesPerPage, page * entriesPerPage);

  // RSV added for Moodle access
  const handleMoodleAccess = async (moodleUrl) => {
    try {
        console.log('=== Starting Moodle Access Process ===');
        console.log('Environment:', process.env.NODE_ENV);
        console.log('Target URL:', moodleUrl);
        
        if (!student.is_moodle) {
            setSnackBarMessage('You are not authorized to access Moodle');
            return;
        }

        const username = Cookies.get('username');
        const password = Cookies.get('password');
        
        if (!username || !password) {
            setSnackBarMessage('Moodle credentials not found. Please log in again.');
            return;
        }

        // Step 1: Authenticate with Moodle
        console.log('Step 1: Authenticating with Moodle...');
        const loginResponse = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/auth/moodle-login`,
            { username, password },
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        );

        console.log('Moodle login response:', loginResponse.data);

        if (loginResponse.data.status === "success") {
            // Step 2: Get redirect URL with credentials
            console.log('Step 2: Getting redirect URL...');
            const redirectResponse = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/auth/moodle-redirect`,
                { 
                    targetUrl: moodleUrl,
                    username,
                    password
                },
                { 
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );

            console.log('Redirect response:', redirectResponse.data);

            if (redirectResponse.data.redirectUrl) {
                // Create a hidden form for POST submission
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = `${process.env.REACT_APP_BACKEND_URL}/auth/moodle-redirect`;
                form.target = '_blank';
                form.style.display = 'none';

                // Add all necessary fields
                const addField = (name, value) => {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = name;
                    input.value = value;
                    form.appendChild(input);
                };

                addField('username', username);
                addField('password', password);
                addField('targetUrl', moodleUrl);
                addField('autoSubmit', 'true'); // Flag to indicate automatic submission

                // Add the form to the document and submit it
                document.body.appendChild(form);
                form.submit();
                document.body.removeChild(form);
            } else {
                throw new Error('No redirect URL received');
            }
        } else {
            throw new Error('Moodle authentication failed');
        }

    } catch (error) {
        console.error('Moodle access error:', error);
        console.error('Error details:', {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status
        });
        
        if (error.response?.status === 401) {
            setSnackBarMessage("Moodle session could not be established. Please try logging in again.");
        } else {
            setSnackBarMessage(`Error accessing Moodle exam: ${error.response?.data?.message || error.message}`);
        }
    }
};

  // Add a test function to verify the form submission
  const testMoodleFormSubmission = () => {
    if (!selectedExam?.exam_link) {
        alert('No exam link available');
        return;
    }

    const username = Cookies.get('username');
    const password = Cookies.get('password');
    
    if (!username || !password) {
        alert('No credentials found');
        return;
    }

    // Create test form
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${process.env.REACT_APP_BACKEND_URL}/auth/moodle-redirect`;
    form.target = '_blank';

    // Add fields
    const fields = {
        username,
        password,
        targetUrl: selectedExam.exam_link,
        autoSubmit: 'true'
    };

    Object.entries(fields).forEach(([name, value]) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = value;
        form.appendChild(input);
    });

    // Submit
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
};

  // Add a helper function to view cookies
  window.viewMoodleCookies = () => {
    const cookies = document.cookie.split(';').reduce((acc, cookie) => {
      const [name, value] = cookie.trim().split('=');
      acc[name] = value;
      return acc;
    }, {});
    console.table(cookies);
  };

  // Add this button click handler
  /*const handleExamStart = (exam) => {
    console.log('=== Handle Exam Start ===');
    console.log('Exam:', exam);
    const isMoodleExam = exam.exam_link && exam.exam_link.includes('soapmoodle.upou.edu.ph');
    //console.log('Is Moodle exam:', isMoodleExam);
    //console.log('Student Moodle status:', student?.is_moodle);
    //console.log('Environment:', process.env.NODE_ENV);
    
    if (student.user_status === "pending") {
        console.log('Student status is pending');
        handlePending();
        return;
    }

    setSelectedExam(exam);
    
    //handleOpenModal(); 
    if (student.is_moodle && isMoodleExam) {
        console.log('Starting Moodle access flow');
        handleMoodleAccess(exam.exam_link);
    } else {
        console.log('Opening regular exam modal');
        handleOpenModal();
    }


  };*/

  // Add this useEffect to log when the component mounts
  useEffect(() => {
    // Check if we're returning from a Moodle redirect
    const redirectPending = sessionStorage.getItem('moodleRedirectPending');
    if (redirectPending) {
      console.log('Returned from Moodle redirect');
      sessionStorage.removeItem('moodleRedirectPending');
    }
  }, []);

  // Add this at the start of your component
  useEffect(() => {
    console.log('%c Moodle Access Debugging Enabled ', 'background: #222; color: #bada55');
    
    // Create a persistent log in localStorage
    const logToStorage = (message) => {
      const logs = JSON.parse(localStorage.getItem('moodleLogs') || '[]');
      logs.push({
        timestamp: new Date().toISOString(),
        message
      });
      localStorage.setItem('moodleLogs', JSON.stringify(logs));
    };

    // Override console.log for this component
    const originalLog = console.log;
    console.log = (...args) => {
      originalLog.apply(console, args);
      logToStorage(args.map(arg => 
        typeof arg === 'object' ? JSON.stringify(arg) : arg
      ).join(' '));
    };

    return () => {
      console.log = originalLog;
    };
  }, []);

  // Add this function to your component
  const viewMoodleLogs = () => {
    const logs = JSON.parse(localStorage.getItem('moodleLogs') || '[]');
    console.table(logs);
  };

  // Add these debug functions at the top of your component
  const debugLog = (message, data = null) => {
    const timestamp = new Date().toISOString();
    const logMessage = data ? `${message}: ${JSON.stringify(data)}` : message;
    console.log(`[${timestamp}] ${logMessage}`);
    
    // Also log to localStorage for persistence
    const logs = JSON.parse(localStorage.getItem('examTableLogs') || '[]');
    logs.push({ timestamp, message: logMessage });
    localStorage.setItem('examTableLogs', JSON.stringify(logs));
  };

  // Add this useEffect at the start of your component
  useEffect(() => {
    debugLog('ExamTable component mounted');
    return () => {
      debugLog('ExamTable component unmounted');
    };
  }, []);

  // Add this function to your component
  const debugMoodleSession = async () => {
    try {
        console.log('=== Moodle Session Debug ===');
        
        // Check all cookies
        console.log('All cookies:', document.cookie.split(';').map(c => c.trim()));
        
        // Check session storage
        console.log('Session storage:', {
            moodleExamInfo: sessionStorage.getItem('moodleExamInfo'),
            moodleRedirectPending: sessionStorage.getItem('moodleRedirectPending')
        });
        
        // Check backend session
        const sessionCheck = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/auth/check-cookies`,
            { withCredentials: true }
        );
        console.log('Backend session check:', sessionCheck.data);
        
    } catch (error) {
        console.error('Debug error:', error);
    }
  };

  const debugCookies = async () => {
    try {
        console.log('=== Cookie Debug ===');
        console.log('Document cookies:', document.cookie);
        
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/auth/debug-cookies`,
            { withCredentials: true }
        );
        console.log('Server cookie debug:', response.data);
        
        // Test Moodle session
        const moodleResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/auth/check-cookies`,
            { withCredentials: true }
        );
        console.log('Moodle session check:', moodleResponse.data);
    } catch (error) {
        console.error('Debug error:', error);
    }
  };

  // Add a function to check authentication status
  const checkAuthStatus = async () => {
    try {
        const accessToken = Cookies.get('accessToken');
        if (!accessToken) {
            console.log('No access token found');
            return false;
        }

        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/auth/check-cookies`,
            {
                withCredentials: true,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }
        );

        return response.data.valid;
    } catch (error) {
        console.error('Auth check error:', error);
        return false;
    }
  };

  useEffect(() => {
    console.log('Current exams:', exams);
    console.log('Current student:', student);
  }, [exams, student]);

  /*
                  <button 
                      className="bg-gold hover:bg-yellow-400 text-black rounded-md py-0.5 px-4 text-sm font-medium mr-2"
                      onClick={() => {
                        console.log('Take Exam button clicked');
                        console.log('Exam details:', exam);
                        console.log('Is Moodle exam:', !!exam.exam_link);
                        handleExamStart(exam);
                      }}
                    >
                      Take Exam
                    </button>
*/

  return (
    <>
    <div className="flex justify-between items-center mb-4 w-screen align-middle" style={{ maxWidth: "1200px" }}>
        <div className="flex items-center justify-center align-middle w-screen space-x-4">
          <input
            type="text"
            placeholder="Search exam"
            className="border border-light-gray rounded-md p-2 text-md w-full"
            style={{ maxWidth: "400px" }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <StatusFilter onStatusChange={handleStatusChange} />
        </div>
      </div>
      <table className="divide-y divide-light-gray w-2/3" style={{
        maxWidth:"800px", tableLayout: "fixed"
      }}>
        <thead className="font-bold">
          <tr>
            <th scope="col" className="px-3 py-3.5 text-left text-md font-semibold" style={{ width: "25%" }}>
              Exam Code
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-md font-semibold cursor-pointer"
              style={{ width: "35%" }}
              onClick={() => handleSort("title")}
            >
              Title
              {sortColumn === "title" ? (
                <FontAwesomeIcon
                  icon={sortOrder === "asc" ? faArrowUp : faArrowDown}
                  className="ml-2 opacity-100"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faArrowUp}
                  className="ml-2 opacity-25"
                />
              )}
              </th>
              <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-md font-semibold cursor-pointer"
                    style={{ width: "25%" }}
                    onClick={() => handleSort("exam_status")}
                >
                    Status
                    {sortColumn === "exam_status" ? (
                        <FontAwesomeIcon
                            icon={sortOrder === "asc" ? faArrowUp : faArrowDown}
                            className="ml-2 opacity-100"
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faArrowUp}
                            className="ml-2 opacity-25"
                        />
                    )}
              </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4" style={{ width: "15%" }}></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-light-gray">
          {paginatedExams.map((exam) => (
            <tr key={exam.id}>
              <td className="whitespace-nowrap px-3 py-3.5 text-md font-medium text-dark-gray" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {exam.exam_code}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-md text-dark-gray" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {exam.title}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-md text-dark-gray" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                <ExamStatus examStatus={exam.exam_status} />
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-md text-dark-gray">
                {exam.exam_status === "Not Taken" && (
                  <>                    
                    <button 
                      className="bg-gold hover:bg-yellow-400 text-black rounded-md py-0.5 px-4 text-sm font-medium mr-2"
                      onClick={() => { 
                        if (!(student.user_status === "pending")) {
                          setSelectedExam(exam);
                          handleOpenModal();
                        } else {
                          handlePending();
                        }
                      }}
                    >
                      Take Exam
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
        {isModalOpen && (
          <Modal
            onClose={handleCloseModal}
            closeText="Cancel"
            actionButton="Start Exam"
            onAction={handleStartExam}
            actionColor="maroon"
            actionColorText="white"
          >
            <h2 className="text-lg font-semibold">Would you like to begin your exam?</h2>
            <hr className="my-4"/>
            <p className="mt-2 mb-6">
              Upon clicking "Start Exam", you consent to the collection and analysis of your webcam data for cheating detection purposes. Your privacy is important to us. All data will be handled securely and only used for exam integrity.
            </p>
          </Modal>
        )}
      </table>
      <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      {snackBarMessage && (
        <Snackbar
          message={snackBarMessage}
          duration={3000}
          onClose={() => setSnackBarMessage('')}
        />
      )}
      {/* Debug panel always visible */}      
      {/*
      <div className="fixed bottom-4 right-4 bg-gray-800 text-white p-4 rounded-lg shadow-lg z-50">
        <h3 className="font-bold mb-2">Debug Panel</h3>
        <div className="space-y-2">
            <div className="text-xs mb-2">
                <p>Current Environment: {process.env.NODE_ENV}</p>
                <p>Backend URL: {process.env.REACT_APP_BACKEND_URL}</p>
                <p>Selected Exam: {selectedExam ? JSON.stringify(selectedExam.exam_link) : 'none'}</p>
                <p>Student Moodle Status: {student ? JSON.stringify(student.is_moodle) : 'unknown'}</p>
            </div>
            <button
                onClick={async () => {
                    console.log('Current cookies:', document.cookie);
                    const response = await axios.get(
                        `${process.env.REACT_APP_BACKEND_URL}/auth/check-cookies`,
                        { withCredentials: true }
                    );
                    console.log('Moodle cookies check:', response.data);
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
            >
                Check Cookies
            </button>
            <button
                onClick={() => {
                    const cookies = document.cookie.split(';').reduce((acc, cookie) => {
                        const [name, value] = cookie.trim().split('=');
                        acc[name] = value;
                        return acc;
                    }, {});
                    console.table(cookies);
                }}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full"
            >
                View All Cookies
            </button>
            <button
                onClick={() => {
                    console.log('Selected Exam:', selectedExam);
                    console.log('Student:', student);
                    console.log('Moodle Status:', student?.is_moodle);
                    console.log('Quiz URL:', selectedExam?.exam_link);
                }}
                className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded w-full"
            >
                Debug Exam Info
            </button>
            <button
                onClick={async () => {
                    const isAuthenticated = await checkAuthStatus();
                    console.log('Authentication status:', isAuthenticated);
                    alert(`Authentication status: ${isAuthenticated}`);
                }}
                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded w-full"
            >
                Check Auth Status
            </button>
            <button
                onClick={testMoodleFormSubmission}
                className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded w-full"
            >
                Test Moodle Redirect
            </button>
        </div>
      </div>
      */}
    </>
  );
}

export default ExamTable;
