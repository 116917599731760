import React, {useState, useEffect} from "react";
import Header from "../components/Header";
import Modal from "../components/Modal";
import axios from "axios";
import Cookies from 'js-cookie';
import Pagination from "../components/Pagination";
import { useNavigate } from "react-router-dom";

// RSV - for moodle integration
const MOODLE_TOKEN = process.env.MOODLE_TOKEN;
const MOODLE_URL = process.env.MOODLE_URL;

const Users = () => {

    const [users, setUsers] = useState([]);
    const [showApproveModal, setshowApproveModal] = useState(false);
    const [showRejectModal, setshowRejectModal] = useState(false);
    const [showPhotoPopup, setShowPhotoPopup] = useState(false); 
    const [currentPhotoUrl, setCurrentPhotoUrl] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const entriesPerPage = 10;
    const [totalPages, setTotalPages] = useState(Math.ceil(users.length / entriesPerPage));
    const navigate = useNavigate();

    // RSV added
    const email = Cookies.get('email'); 

    // RSV - Add new state variables
    const [activeTab, setActiveTab] = useState('pending'); // 'pending' or 'all'
    const [editingUser, setEditingUser] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    // RSV - Add useEffect to handle user role and navigate to appropriate page
    useEffect(() => {
        const userRole = Cookies.get('userRole');
        if (userRole === undefined) {
            navigate('/login');
        } else if (userRole === 'examinee') {
            navigate('/student-dashboard');
        } else if (userRole === 'teacher') {
            activeTab === 'pending' ? fetchStudents() : fetchAllUsers();
        } else if (userRole === 'admin') {
            activeTab === 'pending' ? fetchUsers() : fetchAllUsers();
        }
    }, [activeTab])

    const handleApprove = (userId) => {
        const accessToken = Cookies.get('accessToken');
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/approve/${userId}`,{
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then((response) => {
            // Handle success (e.g., update UI or show a success message)
            console.log("User approved successfully", response);          
            
            // Optionally, refresh the users list to reflect the change
            fetchAllUsers(); // Assuming you have a function to fetch users
        })
        .catch((error) => {
            // Handle error
            console.error("Error approving user", error);
        });
        setshowApproveModal(false);
    }

    

    const handleReject = (userId) => {
        const accessToken = Cookies.get('accessToken');
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/reject/${userId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then((response) => {
            // Handle success (e.g., update UI or show a success message)
            console.log("User approved successfully", response);
            // Optionally, refresh the users list to reflect the change
            fetchAllUsers(); // Assuming you have a function to fetch users
        })
        .catch((error) => {
            // Handle error
            console.error("Error approving user", error);
        });
        setshowRejectModal(false);
    }

    const fetchUsers = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/status/pending`,{
            headers: {
                Authorization: `Bearer ${Cookies.get('accessToken')}`
            }
        })
        .then((res) => {
            console.log(res.data)
            setUsers(res.data)
        })
        .catch((error) => {
            console.error(error)
        })
    }

    const fetchStudents = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/status/pending`,{
            headers: {
                Authorization: `Bearer ${Cookies.get('accessToken')}`
            }
        })
        .then((res) => {
            console.log(res.data)
            // loop through the array of users and filter out the students
            const students = res.data.filter(user => user.user_role === 'examinee');
            setUsers(students)
        })
        .catch((error) => {
            console.error(error)
        })
    }

    // RSV - Fetch all users
    const fetchAllUsers = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/users`,{
            headers: {
                Authorization: `Bearer ${Cookies.get('accessToken')}`
            }
        })
        .then((res) => {
            setUsers(res.data)
        })
        .catch((error) => {
            console.error(error)
        })
    }

    const handleViewPhoto = (url) => {
        const fullUrl = `${process.env.REACT_APP_BACKEND_URL}/${url}`;
        setCurrentPhotoUrl(fullUrl);
        setShowPhotoPopup(true);
    };

    const filteredUsers = users.filter(user => {
        const matchesSearchTerm = 
            `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.student_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase());
        return matchesSearchTerm;
    });    

    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Assuming the popup has a unique ID or class, e.g., 'popup'
            if (showPhotoPopup && !document.getElementById('popup').contains(event.target)) {
                setShowPhotoPopup(false);
            }
        };

        if (showPhotoPopup) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showPhotoPopup]); // Dependency array ensures this effect runs only when showPhotoPopup changes

    useEffect(() => {
        setTotalPages(Math.max(1, Math.ceil(filteredUsers.length / entriesPerPage)));
      }, [filteredUsers.length]);

    const paginatedUsers = filteredUsers.slice((page - 1) * entriesPerPage, page * entriesPerPage);
    
    // RSV - Added for Moodle integration
    const isTeacher = Cookies.get('isTeacher') === 'true'
    const isAdmin = Cookies.get('isAdmin') === 'true'
    const isStudent = Cookies.get('isStudent') === 'true'
    //console.log("isTeacher: ", isTeacher);
    //console.log("isAdmin: ", isAdmin);
    //console.log("isStudent: ", isStudent);    
    // RSV -------------------------------------

    // RSV - Add function to handle user updates
    const handleUpdateUser = (userId) => {
        const accessToken = Cookies.get('accessToken');
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/${userId}`, {
            user_role: editingUser.user_role,
            user_status: editingUser.user_status,
            profile_picture: editingUser.profile_picture
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then((response) => {
            console.log("User updated successfully", response);
            fetchAllUsers();
            setShowEditModal(false);
        })
        .catch((error) => {
            console.error("Error updating user", error);
        });
    }

    // RSV - Add delete handler function
    const handleDeleteUser = (userId) => {
        const accessToken = Cookies.get('accessToken');
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/users/${userId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then((response) => {
            console.log("User deleted successfully", response);
            fetchAllUsers();
            setShowDeleteModal(false);
        })
        .catch((error) => {
            console.error("Error deleting user", error);
        });
    }

    return (
        <div className="flex flex-col bg-body w-screen h-min-screen">
            <Header currentPage='users' isTeacher={isTeacher} isAdmin={isAdmin} isStudent={isStudent} email={email}/>            
            <div className="flex flex-col min-h-screen w-screen h-full items-center">
                {/* Add tabs */}            
                <h1 className="flex w-full text-2xl font-bold text-maroon justify-center py-10">
                    {/*activeTab === 'pending' ? 'Pending Users' : 'All Users'*/}
                    <div className="flex w-2/3 justify-center space-x-4 mb-4">
                        <button 
                            className={`px-4 py-2 font-semibold rounded-t-md ${activeTab === 'pending' ? 'bg-maroon text-white' : 'bg-gray-200'}`}
                            onClick={() => setActiveTab('pending')}
                        >
                            Pending Users
                        </button>
                        <button 
                            className={`px-4 py-2 font-semibold rounded-t-md ${activeTab === 'all' ? 'bg-maroon text-white' : 'bg-gray-200'}`}
                            onClick={() => setActiveTab('all')}
                        >
                            All Users
                        </button>
                    </div>
                </h1>
                <div className="flex flex-col w-2/3 align-middle justify-center ">
                    <div className="flex items-center justify-center align-middle w-full mb-6">
                        <input
                            type="text"
                            placeholder="Search user"
                            className="border border-light-gray rounded-md p-2 text-md w-full"
                            style={{ maxWidth: "1200px" }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    {users.length>0 ? <table className="divide-y divide-light-gray">
                        <thead>
                            <tr>
                                <th scope="col" className="px-3 py-3.5 text-left text-md font-semibold">
                                    Student/Employee Number
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-md font-semibold">
                                    Name
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-md font-semibold">
                                    Role
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-md font-semibold">
                                    Email
                                </th>
                                {activeTab === 'all' && (
                                    <th scope="col" className="px-3 py-3.5 text-left text-md font-semibold">
                                        Status
                                    </th>
                                )}
                                <th scope="col" className="px-3 py-3.5 text-left text-md font-semibold">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-light-gray">
                            {paginatedUsers.map((user) => (
                                <tr key={user.id}>
                                    <td className="whitespace-nowrap px-3 py-4 text-md font-semibold text-dark-gray ">
                                    {user.employee_number ? user.employee_number : user.student_number}
                                    </td>
                                    <td className="whitespace-nowrap pl-3 pr-6 py-4 text-md text-dark-gray  text-left">
                                    {user.first_name} {user.last_name}
                                    </td>
                                    <td className="whitespace-nowrap pl-3 pr-6 py-4 text-md text-dark-gray">
                                    {user.user_role}
                                    </td>
                                    <td className="whitespace-nowrap pl-3 pr-6 py-4 text-md text-dark-gray">
                                    {user.email}
                                    </td>
                                    {activeTab === 'all' && (
                                        <td className="whitespace-nowrap px-3 py-4 text-md text-dark-gray">
                                            {user.user_status}
                                        </td>
                                    )}
                                    <td className="whitespace-nowrap px-10 py-4 text-sm font-semibold text-maroon">
                                    {user.profile_picture && <a href="#" onClick={() => handleViewPhoto(user.profile_picture)} className="hover:opacity-70"> View Uploaded Image </a>}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-md text-dark-gray">
                                    {activeTab === 'pending' ? (
                                        <>
                                        <button className="bg-green-button px-4 py-1 font-semibold mx-6 rounded-md text-body hover:opacity-70"
                                            onClick={() => {setSelectedUser(user); setshowApproveModal(true)}}
                                        >
                                            Approve
                                        </button>
                                        <button className="bg-maroon px-6 py-1 font-semibold mx-6 rounded-md text-body hover:opacity-70"
                                            onClick={() => {setSelectedUser(user); setshowRejectModal(true)}}
                                        >
                                            Reject
                                        </button>
                                        </>
                                    ) : (
                                        <div className="flex space-x-2">
                                            <button 
                                                className="bg-green-button px-4 py-1 font-semibold rounded-md text-white hover:opacity-70"
                                                onClick={() => {
                                                    setEditingUser(user);
                                                    setShowEditModal(true);
                                                }}
                                            >
                                                Edit
                                            </button>
                                            <button 
                                                className="bg-maroon px-4 py-1 font-semibold rounded-md text-white hover:opacity-70"
                                                onClick={() => {
                                                    setSelectedUser(user);
                                                    setShowDeleteModal(true);
                                                }}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    )}
                                    </td>   
                                </tr>
                            ))}
                        </tbody>
                    </table> : `No ${activeTab === 'pending' ? 'Pending' : ''} Users`}
                    <div className="flex w-full items-center justify-center align-middle py-6">
                    <Pagination page={page} setPage={setPage} totalPages={totalPages} />
                    </div>
                </div>
                
            </div>
            {showApproveModal && (
                <Modal
                    onClose={() => setshowApproveModal(false)}
                    closeText="Cancel"
                    actionButton="Approve"
                    onAction={() => handleApprove(selectedUser._id)}
                    actionColor="green-button"
                    actionColorText="white"
                >
                    <h2 className="text-lg font-semibold">Do you want to accept this user's registration request?</h2>
                    <hr className="my-4"/>
                    <p className="mt-2 mb-6">
                    By confirming you are, approving this user's registration request and confirming that the uploaded image is the person itself and understand that this action cannot be undone.
                    </p>
                </Modal>
            )}
            {showRejectModal && (
                <Modal
                    onClose={() => setshowRejectModal(false)}
                    closeText="Cancel"
                    actionButton="Reject"
                    onAction={() => handleReject(selectedUser._id)}
                    actionColor="maroon"
                    actionColorText="white"
                >
                    <h2 className="text-lg font-semibold">Do you want to reject this user's registration request??</h2>
                    <hr className="my-4"/>
                    <p className="mt-2 mb-6">
                        By confirming you are, rejecting this user's registration request and understand that this action cannot be undone.
                    </p>
                </Modal>
            )}
            {showPhotoPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div id="popup" className="relative bg-white p-4 rounded-lg overflow-auto" style={{ height: '40vw', maxWidth: '100%', maxHeight: '100%' }} >
                        <img src={currentPhotoUrl} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    </div>
                </div>
            )}
            {showEditModal && (
                <Modal
                    onClose={() => setShowEditModal(false)}
                    closeText="Cancel"
                    actionButton="Update"
                    onAction={() => handleUpdateUser(editingUser._id)}                    
                    actionColor="green-button"
                    actionColorText="white"
                >
                    <h2 className="text-lg font-semibold">Edit User</h2>
                    <hr className="my-4"/>
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Role</label>
                            <select 
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                value={editingUser.user_role}
                                onChange={(e) => setEditingUser({...editingUser, user_role: e.target.value})}
                            >
                                <option value="admin">Admin</option>
                                <option value="teacher">Teacher</option>
                                <option value="examinee">Examinee</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Status</label>
                            <select 
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                value={editingUser.user_status}
                                onChange={(e) => setEditingUser({...editingUser, user_status: e.target.value})}
                            >
                                <option value="accepted">Accepted</option>
                                <option value="pending">Pending</option>
                                <option value="rejected">Rejected</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Profile Picture URL</label>
                            <input 
                                type="text"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                value={editingUser.profile_picture || ''}
                                onChange={(e) => setEditingUser({...editingUser, profile_picture: e.target.value})}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {showDeleteModal && (
                <Modal
                    onClose={() => setShowDeleteModal(false)}
                    closeText="Cancel"
                    actionButton="Delete"
                    onAction={() => handleDeleteUser(selectedUser._id)}
                    actionColor="maroon"
                    actionColorText="white"
                >
                    <h2 className="text-lg font-semibold">Delete User</h2>
                    <hr className="my-4"/>
                    <p className="mt-2 mb-6">
                        Are you sure you want to delete this user? This action cannot be undone.
                    </p>
                </Modal>
            )}
        </div> 
    );
}

export default Users;